import react from "react";

const About = () => {
  return (
    <div className="aboutus">
      <div className="sub-aboutus">
        <h1 id="servhed" text-align="center">
          ABOUT US
        </h1>

        <p>
          LitRental is a wormhole to your future house! Find hostels, PGs and
          other rental properties nearest to your location and experience the
          smoothest journey of finding a new house. With our seamless user
          experience, you can directly chat with the property owner, book a call
          or schedule a visit. Choose from a number of verified user properties
          and get your next home in just a few clicks.
        </p>

        <h2>Do you own a property and want to rent it?</h2>
        <p>
          Hello there, landlords! It's time to remove dust from your empty block
          because we are here. All you need is to get registered with us. Our
          team will help you to verify your profile and upload all the necessary
          documents to the website. It doesn't end there! We'll connect you with
          hundreds of tenants that are looking for you. Once the deal is done,
          we will help you automate the process of collecting monthly rent.
          Remember, once you are fully verified, the chances of getting hiked on
          the website skyrocket! So, you know what to do.
        </p>

        <h2>Want us to summarize?</h2>
        <p>
          -Think about us when you're in a completely new city and need a
          PG/Hostel.
        </p>
        <p>-Think about us when you need a place to settle.</p>
        <p>-Think about us if you're struggling to find the right tenants.</p>
      </div>
    </div>
  );
};

export default About;
