import { createContext, useState, useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
} from "firebase/auth";

import { auth } from "../firebase.config";

const UserContext = createContext();

const UserState = (props) => {
  const [user, setuser] = useState(null);
  const [loggedin, setloggedin] = useState(false);

  const signup = (email, password) => {
    const newUserCredentials = createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    newUserCredentials
      .then((cred) => {
        sendEmailVerification(cred.user)
          .then((email) => {
            console.log("email sent ", email);
          })
          .catch((err) => {
            console.log(err);
          });
        console.log("User created successfully");
      })
      .catch((error) => {
        console.log("Error creating user:", error);
      });
    return newUserCredentials;
  };

  const login = (email, password) => {
    setloggedin(true);
    return signInWithEmailAndPassword(auth, email, password);
  };

  const logout = async () => {
    setloggedin(false);
    await setuser(null);
    return signOut(auth);
  };

  const forgotPassword = async (email) => {
    setloggedin(true);
    sendPasswordResetEmail(email)
      .then(function () {
        alert("Please check your email...");
      })
      .catch(function (e) {
        console.log(e);
      });
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentstate) => {
      setuser(currentstate);
    });

    return () => {
      unsubscribe();
    };
  });

  return (
    <UserContext.Provider
      value={{ user, signup, login, logout, forgotPassword }}
    >
      {" "}
      {props.children}{" "}
    </UserContext.Provider>
  );
};

export { UserState, UserContext };
