import { formreducer } from "./form1";
import { rentreducer } from "./rentform";
import { pgformreducer } from "./pgform";
import { studioformreducer } from "./studioform";

import { combineReducers } from "redux";

export const rootReducer = combineReducers({
  formreducer,
  rentreducer,
  pgformreducer,
  studioformreducer,
});
