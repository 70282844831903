import React, { useContext } from "react";
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Card,
  Button,
  InputGroup,
  FormControl,
  Form,
  Table,
} from "react-bootstrap";
import { db } from "../firebase.config";
import { useEffect, useState } from "react";
import {
  getDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  query,
  doc,
  collection,
  addDoc,
  where,
} from "firebase/firestore";
import { pagesofhostel } from "../Service/Operation";
import { UserContext } from "../Context/UserContext";
import { AuthContext } from "../Context/AuthContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Dashboard = () => {
  const cont = useContext(UserContext);
  console.log("user", cont.user);
  const [hostelcount, sethostelcount] = useState(0);
  const [propertycount, setpropertycount] = useState(0);
  const [pgcount, setpgcount] = useState(0);
  const [studiocount, setstudiocount] = useState(0);

  const history = useHistory();

  useEffect(async () => {
    console.log("inside if");
    console.log("constuid = ", cont.user.uid);
    fun();
  }, [cont.user !== null]);

  useEffect(async () => {
    console.log("inside if");
    console.log("constuid = ", cont.user.uid);
    fun2();
  }, [cont.user !== null]);

  useEffect(async () => {
    console.log("inside if");
    console.log("constuid = ", cont.user.uid);
    fun3();
  }, [cont.user !== null]);

  useEffect(async () => {
    console.log("inside if");
    console.log("constuid = ", cont.user.uid);
    fun4();
  }, [cont.user !== null]);

  function fun() {
    new Promise(async (resolve, reject) => {
      const hostelobjectref = collection(db, "HostelObject");
      const q = await query(
        hostelobjectref,
        where("userid", "==", `${cont.user.uid}`)
      );

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach(async (doc) => {
        console.log(doc.id, " => ", doc.data());
        sethostelcount((prev) => prev + 1);
      });
    });
  }
  function fun2() {
    new Promise(async (resolve, reject) => {
      const RentObjectref = collection(db, "RentObject");
      const q = await query(
        RentObjectref,
        where("userid", "==", `${cont.user.uid}`)
      );

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach(async (doc) => {
        console.log(doc.id, " => ", doc.data());
        setpropertycount((prev) => prev + 1);
      });
    });
  }

  function fun3() {
    new Promise(async (resolve, reject) => {
      const PGtObjectref = collection(db, "PGObject");
      const q = await query(
        PGtObjectref,
        where("userid", "==", `${cont.user.uid}`)
      );

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach(async (doc) => {
        console.log(doc.id, " => ", doc.data());
        setpgcount((prev) => prev + 1);
      });
    });
  }

  function fun4() {
    new Promise(async (resolve, reject) => {
      const StudiotObjectref = collection(db, "StudioObject");
      const q = await query(
        StudiotObjectref,
        where("userid", "==", `${cont.user.uid}`)
      );

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach(async (doc) => {
        console.log(doc.id, " => ", doc.data());
        setstudiocount((prev) => prev + 1);
      });
    });
  }

  return (
    <div className="dashboard">
      <h1>DASHBOARD</h1>

      <div className="container">
        <div className="box">
          <h1>{hostelcount}</h1>
          <h1>Hostels</h1>
        </div>

        <div className="box">
          <h1>{propertycount}</h1>
          <h1>Property</h1>
        </div>

        <div className="box">
          <h1>{pgcount}</h1>
          <h1>PG</h1>
        </div>
        <div className="box">
          <h1>{studiocount}</h1>
          <h1>Studio Apartment</h1>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
