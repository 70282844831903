import React from "react";

const Footer = () => {
  const CURRENT = new Date();
  let year = CURRENT.getFullYear();
  return (
    <div>
      <div className="outercontact">
        <div className="innercontact1">
          <div className="img_container">
            <img src="images/lit logo.png" alt="images" />
          </div>
          <div className="aligned_boxes">
            <div className="aligned">
              <div class="footer-icons">
                <a href="https://www.facebook.com/robo.folks">
                  <i class="fa fa-envelope"></i>
                </a>
              </div>
              <div className="start">
                <h3> liveintemporaryrental@gmail.com</h3>
              </div>
            </div>
            <div className="aligned">
              <div class="footer-icons">
                <a href="https://www.facebook.com/profile.php?id=100082066914870">
                  <i class="fa fa-phone"></i>
                </a>
              </div>
              <div className="start">
                <h3>+91903959905</h3>
                <h3>+91903959906</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="innercontact2">
          <div className="contactushead">
            <h3 text-color="#001a68" className="contactushead">
              Let's Get in Touch
            </h3>
            <h3 text-color="#001a68" className="contactushead hh3">
              Contact us
            </h3>
            <h3 className="contactushead">
              Can add property and manage and allocate{" "}
            </h3>
          </div>
          <div className="test">
            <input
              className="inputcss"
              placeholder="Name"
              type="text"
              name="email"
            />
            <input
              className="inputcss"
              placeholder="Email"
              type="text"
              name="email"
            />
          </div>
          <div className="test">
            <input
              className="inputcss"
              placeholder="Address"
              type="text"
              name="email"
            />
            <input
              className="inputcss"
              placeholder="Phone number"
              type="text"
              name="email"
            />
          </div>
          <div className="test">
            <input
              className="inputcss css"
              placeholder="Write your query.."
              type="text"
              name="email"
            />
          </div>
          <div className="centeralg">
            <button className="servicebtn">Send</button>
          </div>
        </div>
      </div>

      <div className="footer">
        <footer class="footer-distributed">
          <div class="footer-center">
            <h3>Company</h3>
            <ul>
              <li>About Us</li>
              <li>Contact us</li>
            </ul>
          </div>
          <div class="footer-left">
            <h3>Rental Solution</h3>
            <div class="footer-icons">
              <a href="https://www.facebook.com/profile.php?id=100082066914870">
                <i class="fa fa-facebook"></i>
              </a>
              <a href="https://www.instagram.com/lit_rental/">
                <i class="fa fa-instagram"></i>
              </a>
              <a href="https://www.linkedin.com/company/development-folks">
                <i class="fa fa-linkedin"></i>
              </a>
              <a href="https://twitter.com/lit_intern">
                <i class="fa fa-twitter"></i>
              </a>
            </div>
          </div>

          <div class="footer-right">
            <h3>Company</h3>
            <ul>
              <li>Home</li>
              <li>Rental house</li>
              <li>Villa</li>
              <li>Apartment</li>
            </ul>
          </div>

          <h5 className="copyright">@copyright {year}</h5>
        </footer>
      </div>
    </div>
  );
};

export default Footer;
