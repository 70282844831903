import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
const Service = () => {
  const cont = useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <div className="service">
      <h1 className="h1class h1bg">OUR SERVICES</h1>
      <div className="subservice">
        <div className="servicediv servicedivision" data-aos="slide-right">
          <img
            className="OurServiceLogo"
            src="images/OurServiceLogo1.png"
            alt="images"
          />

          <h2>Funding Efforts</h2>

          <p>
            We help you find a new place to settle in. Choose from numerous
            options available and find your ideal PG, Hostel or house.
          </p>
        </div>

        <div className="servicediv servicedivision" data-aos="slide-down">
          <img
            className="OurServiceLogo"
            src="images/OurServiceLogo2.png"
            alt="images"
          />
          <h2>Funding Efforts</h2>

          <p>
            We take care of all the legalities and documentation that are
            required as per the government guidelines.
          </p>
        </div>

        <div className="servicediv servicedivision" data-aos="slide-left">
          <img
            className="OurServiceLogo"
            src="images/OurServiceLogo3.png"
            alt="images"
          />
          <h2>Funding Efforts</h2>

          <p>
            Seamless renting experience. Get directly in touch with the property
            owner via chat. Also, you can schedule a house visit at your
            convenience.
          </p>
        </div>
      </div>

      <div>
        <h1 id="servhed" className="h1class">
          WE PROVIDE SERVICE FOR
        </h1>
        <div className="addproperty" data-aos="slide-left">
          <div className="servicecontent">
            <h2 className="servhead">PROPERTY OWNERS</h2>
            <p>
              Property owners can directly register themselves on the portal and
              add details about the facilities and availability.{" "}
            </p>
            <button className="addpropertybtn">
              <Link to="/AddProperty" className="textdec">
                ADD-PROPERTY
              </Link>
            </button>
          </div>
          <img
            className="serviceimage"
            src="images/addpropertyimg1.png"
            alt="images"
          />
        </div>

        <div className="searchproperty" data-aos="slide-right">
          <img
            className="serviceimage2"
            src="images/addpropertyimg2.png"
            alt="images"
          />
          <div className="servicecontent">
            <h2 className="servhead">SEARCH PROPERTY</h2>
            <p>
              Tenants can directly search for the available rental property to
              their desired location and can connect with the property owner.
            </p>
            <button className=" addpropertybtn">
              <Link to="/AddProperty" className="textdec">
                ADD-PROPERTY
              </Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
