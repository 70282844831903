import React, { useEffect } from "react";
import AOS from "aos";
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Card,
  Button,
  InputGroup,
  FormControl,
  Form,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";

const Type = () => {
  const cont = useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <div className="proptype">
      <div className="service">
        <h1 className="h1class h1bg" id="h1subservice">
          Post New Listing
        </h1>

        <div className="subservice">
          <div className="servicediv servicedivision" data-aos="slide-right">
            <img
              className="OurServiceLogo"
              src="./images/example-img.png"
              alt="images"
            />
            <h2>Hostel</h2>

            <p>
              Are you a student looking for a cheap place to rent? Hostels are
              perfect for you. Checkout our Hostel section and the details about
              the additional services they have to offer.
            </p>
            <button className="servicebtn">
              <Link to="/HostelForm" className="textdec">
                Hostel
              </Link>
            </button>
          </div>

          <div className="servicediv servicedivision" data-aos="slide-down">
            <img
              className="OurServiceLogo"
              src="./images/example-img.png"
              alt="images"
            />
            <h2>PG</h2>

            <p>
              Wanna be a Paying Guest? Checkout the best PGs at your desired
              location with LitRental. We personally make sure that the places
              are in the safe neighbourhood.
            </p>
            <button className="servicebtn">
              <Link to="/PGForm" className="textdec">
                PG
              </Link>
            </button>
          </div>

          <div className="servicediv servicedivision" data-aos="slide-left">
            <img
              className="OurServiceLogo"
              src="./images/example-img.png"
              alt="images"
            />
            <h2>Houses</h2>

            <p>
              Are you an employee who just got a transfer in a new city? Well,
              we are here to help you set your new home! Find the best houses
              for rent with LitRental.
            </p>
            <button className="servicebtn">
              <Link to="/RentForm" className="textdec">
                Rent
              </Link>
            </button>
          </div>

          <div className="servicediv servicedivision" data-aos="slide-up">
            <img
              className="OurServiceLogo"
              src="./images/example-img.png"
              alt="images"
            />
            <h2>Apartment Studio</h2>

            <p>
              Are you a student looking for a cheap place to rent? Hostels are
              perfect for you. Checkout our Hostel section and the details about
              the additional services they have to offer.
            </p>
            <button className="servicebtn">
              <Link to="/StudioForm" className="textdec">
                Apartment Studio
              </Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Type;
