const initialvalue = null;

export const studioformreducer = (state = initialvalue, action) => {
  console.log("form in studioformreducer", action);
  switch (action.type) {
    case "studioformaction":
      return action.payload;
    case "studioformempty":
      return null;
    default:
      return state;
  }
};
