import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Carousel, Card } from "react-bootstrap";

const Carousell = () => {
  return (
    <Carousel>
      <Carousel.Item className="carouselclass">
        <div className="carousel">
          <div className="carouselText">
            <h1>LITRENTAL</h1>
            <h5>LIVE IN TEMPORARY RENTAL</h5>
            <p>
              Litrental helps you find your new home. Explore the listing and
              find the perfect house at your desired location. Get ready to find
              a lit home with LitRental.
            </p>
            <button className="servicebtn "> ARE YOU READY? </button>
          </div>
          <img
            className="carouselImg"
            src="images/Carousel Image.png"
            alt="images"
          />
        </div>
      </Carousel.Item>
      <Carousel.Item className="carouselclass">
        <div className="carousel">
          <div className="carouselText">
            <h1>LITRENTAL</h1>
            <h5>LIVE IN TEMPORARY RENTAL</h5>
            <p>
              Wondering where to crash after you reach a completely new city? We
              got you covered!{" "}
            </p>
            <button className="servicebtn ">ARE YOU READY?</button>
          </div>
          <img
            className="carouselImg"
            src="images/Carousel Image.png"
            alt="images"
          />
        </div>
      </Carousel.Item>
      <Carousel.Item className="carouselclass">
        <div className="carousel">
          <div className="carouselText">
            <h1>LITRENTAL</h1>
            <h5>LIVE IN TEMPORARY RENTAL</h5>
            <p>
              Find hostels, PGs and other rental properties nearest to your
              location.
            </p>
            <button className="servicebtn ">ARE YOU READY?</button>
          </div>

          <img
            className="carouselImg"
            src="images/Carousel Image.png"
            alt="images"
          />
        </div>
      </Carousel.Item>
      <Carousel.Item className="carouselclass">
        <div className="carousel">
          <div className="carouselText">
            <h1>LITRENTAL</h1>
            <h5>LIVE IN TEMPORARY RENTAL</h5>
            <p>
              Directly chat with the property owner, book a call or schedule a
              visit.
            </p>
            <button className="servicebtn ">ARE YOu READY?</button>
          </div>

          <img
            className="carouselImg"
            src="images/Carousel Image.png"
            alt="images"
          />
        </div>
      </Carousel.Item>
    </Carousel>
  );
};

export default Carousell;
