import React, { Component } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { UserContext } from "../Context/UserContext";
import { Link } from "react-router-dom";
import "../css/Form1.css";
import RoomdetailsForm from "./RoomdetailsForm";
import { formaction } from "../actions/action";
import { formreducer } from "../reducers/form1";
import { GiCctvCamera } from "react-icons/gi";
import { SiAdguard } from "react-icons/si";
import { FaFingerprint } from "react-icons/fa";
import { RiFridgeLine } from "react-icons/ri";
import { GiWashingMachine } from "react-icons/gi";
import { MdMicrowave } from "react-icons/md";
import { FiMonitor } from "react-icons/fi";
import { MdCoffeeMaker } from "react-icons/md";
import { GiGasStove } from "react-icons/gi";
import { GiVendingMachine } from "react-icons/gi";
import { RiShirtFill } from "react-icons/ri";
import { GiBroom } from "react-icons/gi";
import { AiOutlineWifi } from "react-icons/ai";
import { CgGym } from "react-icons/cg";
import { GiWaterDrop } from "react-icons/gi";
import { MdLocalParking } from "react-icons/md";
import { GiElevator } from "react-icons/gi";
import { GiCarBattery } from "react-icons/gi";
import { FaSwimmer } from "react-icons/fa";
import { IoLibrarySharp } from "react-icons/io5";
import { FaHandHoldingWater } from "react-icons/fa";
import { FaTableTennis } from "react-icons/fa";
import { ImNewspaper } from "react-icons/im";

export default function Form1() {
  const history = useHistory();

  const cont = useContext(UserContext);
  console.log("cont.user in hostelform = ", cont.user);

  // use dispatch
  const dispatch = useDispatch();
  const mystate = useSelector((state) => state.formreducer);
  // console.log("mystate",mystate)

  // Hostel details usestates
  const [hosteldetails, sethosteldetails] = useState({});
  const [commonarea, setcommonarea] = useState({
    living: false,
    kitchen: false,
    dininghall: false,
    library: false,
  });

  const commonareainputs = (e) => {
    console.log("commonareainputs");
    console.log(e.target.value, e.target.checked);
    commonarea[e.target.value] = e.target.checked;

    console.log(commonarea);
  };

  const hostelinput = (e) => {
    hosteldetails[e.target.name] = e.target.value;
    console.log(hosteldetails);
  };

  // Owner caretaker details
  const [finalownerdetails, setfinalownerdetails] = useState({});

  const ownerdetailsinput = (e) => {
    console.log("owners details input");
    console.log(e.target.value, e.target.name);
    finalownerdetails[e.target.name] = e.target.value;
    console.log(finalownerdetails);
  };

  // Security amenities
  const [security, setsecurity] = useState({
    cctv: false,
    security: false,
    biometric: false,
  });

  const securityinput = (e) => {
    console.log("security input");
    console.log(e.target.value, e.target.checked);
    security[e.target.value] = e.target.checked;
    console.log(security);
  };

  // Furnishing in property
  const [furnishing, setfurnishing] = useState({
    fridge: false,
    washingmachine: false,
    microwave: false,
    waterpurifier: false,
    gasstove: false,
    coffeemachine: false,
    tv: false,
    snackmachine: false,
  });

  const furnishinginput = (e) => {
    console.log("furnishing input");
    console.log(e.target.value, e.target.checked);
    furnishing[e.target.value] = e.target.checked;
    console.log(furnishing);
  };

  // Services
  const [services, setservices] = useState({
    laundry: false,
    housekeeping: false,
    wificonnectivity: false,
  });

  const servicesinput = (e) => {
    console.log("services input");
    console.log(e.target.value, e.target.checked);
    services[e.target.value] = e.target.checked;
    console.log(services);
  };

  // Top Amenities
  const [topamenities, settopamenities] = useState({
    library: false,
    gym: false,
    watersupply: false,
    reservedparking: false,
    lift: false,
    powerbackup: false,
    swimmingpool: false,
    tabletennis: false,
    dailynewspaper: false,
  });

  const topamenitiesinput = (e) => {
    console.log("topamenities input");
    console.log(e.target.value, e.target.checked);
    topamenities[e.target.value] = e.target.checked;
    console.log(topamenities);
  };

  // Other Hostel details
  const [otherpgdetails, setotherpgdetails] = useState({});

  const otherpgdetailsinput = (e) => {
    console.log("other pg details input");
    console.log(e.target.value, e.target.name);
    otherpgdetails[e.target.name] = e.target.value;
    console.log(otherpgdetails);
  };

  // Address details
  const [addressdetails, setaddressdetails] = useState({});

  const addressdetailsinput = (e) => {
    console.log("address details input");
    console.log(e.target.value, e.target.name);
    addressdetails[e.target.name] = e.target.value;
    console.log(addressdetails);
  };

  // Room details
  var idris = 10;

  const [inputList, setInputList] = useState([
    {
      rent: "",
      securitydeposit: "",
      securitydeposit2: "",
      singlesharing: false,
      doublesharing: false,
      triplesharing: false,
      threeplusharing: false,
      personalcupboard: false,
      tvinroom: false,
      tablechair: false,
      attachedbalocony: false,
      attachedbathroom: false,
      ac: false,
    },
  ]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
    console.log("updated inputList = ", inputList);
  };

  const handleInputChange2 = (e, index) => {
    const { name, checked } = e.target;
    const list = [...inputList];
    list[index][name] = checked;
    setInputList(list);
    console.log("updated inputList = ", inputList);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    console.log("after removal inputList = ", inputList);
  };

  const handleAddClick = async () => {
    await setInputList([
      ...inputList,
      {
        rent: "",
        securitydeposit: "",
        securitydeposit2: "",
        singlesharing: false,
        doublesharing: false,
        triplesharing: false,
        threeplusharing: false,
        personalcupboard: false,
        tvinroom: false,
        tablechair: false,
        attachedbalocony: false,
        attachedbathroom: false,
        ac: false,
      },
    ]);
    console.log("after add = ", inputList);
  };

  // submitting the form

  const [finalform, setfinalform] = useState({});

  const submitted = async (e) => {
    e.preventDefault();
    console.log("submitted", finalform);

    const result = {
      roomdetails: inputList,
      hosteldetails: { commonarea: commonarea, inputs: hosteldetails },
      finalownerdetails: finalownerdetails,
      security: security,
      firnishing: furnishing,
      services: services,
      topamenities: topamenities,
      otherpgdetails: otherpgdetails,
      addressdetails: addressdetails,
    };

    console.log("result = ", result);

    dispatch(formaction(result));

    if (cont.user == null) {
      history.push("/login");
    } else {
      history.push("/Addimages");
    }
  };

  return (
    <div className="backgroundimg">
      <form>
        <div className="maindiv">
          <div className="innerdivision">
            <div className="setcolor">
              <h5 className="white">Hey Hostel Owners,</h5>
              <p className="white1">Upload your Hostel as a new listing.</p>
              <h5 className="red">*indicates a mandatory field</h5>
            </div>

            <div>
              <h4 className="iconheading">Hostel Details</h4>
              <div className="HostelInfo">
                <div className="space-ar">
                  <input
                    className="inputcss"
                    id="inputid"
                    name="hostelname"
                    onChange={hostelinput}
                    type="text "
                    placeholder="Hostel Name"
                    required
                  />
                  <br />
                  <input
                    className="inputcss"
                    id="inputid"
                    name="totalbeds"
                    onChange={hostelinput}
                    type="text "
                    placeholder="Total No. of Beds required"
                  />
                  <br />
                  <br />
                </div>

                <div className="space-ar">
                  <div className="radiobuttongroup">
                    <p id="pid">Hostel is for</p>
                    <div class="wrapper">
                      <input
                        type="radio"
                        onChange={hostelinput}
                        name="pgfor"
                        value="girls"
                        id="option-1"
                      />
                      <input
                        type="radio"
                        onChange={hostelinput}
                        name="pgfor"
                        value="boys"
                        id="option-2"
                      />
                      <label for="option-1" class="option option-1">
                        <span>Girls</span>
                      </label>
                      <label for="option-2" class="option option-2">
                        <span>boys</span>
                      </label>
                    </div>
                  </div>

                  <div className="best-suited">
                    <select>
                      <option value="0">Best Suited For</option>
                      <option value="1">Student</option>
                      <option value="2">Working Professional</option>
                      <option value="3">Any</option>
                    </select>
                  </div>
                </div>

                <div className="space-ar">
                  <div className="radiobuttongroup">
                    <p id="pid">Meals available</p>
                    <div class="wrapper">
                      <input
                        type="radio"
                        name="mealsavailable"
                        value="yes"
                        onChange={hostelinput}
                        id="option-3"
                      />
                      <input
                        type="radio"
                        name="mealsavailable"
                        value="no"
                        onChange={hostelinput}
                        id="option-4"
                      />
                      <label for="option-3" class="option option-3">
                        <span>Yes</span>
                      </label>
                      <label for="option-4" class="option option-4">
                        <span>no</span>
                      </label>
                    </div>
                  </div>
                  <input
                    id="inputid"
                    className="inputcss1"
                    name="mealchargepermonth"
                    onChange={otherpgdetailsinput}
                    type="text"
                    placeholder="Meal charge per month"
                    required
                  />
                </div>

                <div className="space-ar">
                  <input
                    className="inputcss"
                    id="inputid"
                    type="text"
                    name="noticeperiod"
                    onChange={hostelinput}
                    placeholder="Notice period"
                    required
                  />
                  <input
                    className="inputcss1"
                    id="inputid"
                    type="text"
                    name="lockinperiod"
                    onChange={hostelinput}
                    placeholder="Lockin period"
                    required
                  />
                </div>

                <div className="space-ar">
                  <p id="pid" className="commonarea">
                    Common area
                  </p>
                  <div className="inputgrp inputgrpcls1">
                    <div class="checkbox-example">
                      <input
                        type="checkbox"
                        onClick={commonareainputs}
                        value="living"
                        id="checkboxOneInput1"
                      />
                      <label for="checkboxOneInput1">Living</label>
                    </div>
                    <div class="checkbox-example">
                      <input
                        type="checkbox"
                        onClick={commonareainputs}
                        value="kitchen"
                        id="checkboxOneInput2"
                      />
                      <label for="checkboxOneInput2">Kitchen</label>
                    </div>
                    <div class="checkbox-example">
                      <input
                        type="checkbox"
                        onClick={commonareainputs}
                        value="dininghall"
                        id="checkboxOneInput3"
                      />
                      <label for="checkboxOneInput3">DiningHall</label>
                    </div>
                    <div class="checkbox-example">
                      <input
                        type="checkbox"
                        onClick={commonareainputs}
                        value="library"
                        id="checkboxOneInput4"
                      />
                      <label for="checkboxOneInput4">Library</label>
                    </div>
                  </div>
                </div>
              </div>

              {/* ///////////////////////////////////////////// Owners details //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}

              <h4 className="iconheading">Owner/Caretaker Details</h4>
              <div className="OwnerDetailInfo">
                <div className="detail1">
                  <div className="space-ar">
                    <div className="radiobuttongroup rbgcol">
                      <p id="pid">Property manager stays at premise?</p>

                      <div class="wrapper">
                        <input
                          type="radio"
                          onChange={ownerdetailsinput}
                          name="staysatpremise"
                          value="yes"
                          id="option-5"
                        />
                        <input
                          type="radio"
                          onChange={ownerdetailsinput}
                          name="staysatpremise"
                          value="no"
                          id="option-6"
                        />
                        <label for="option-5" class="option option-5">
                          <span>Yes</span>
                        </label>
                        <label for="option-6" class="option option-6">
                          <span>no</span>
                        </label>
                      </div>
                    </div>

                    <div className="best-suited">
                      <select style={{ marginLeft: "9%" }}>
                        <option value="0">Property Managed By</option>
                        <option value="1">Owner itself</option>
                        <option value="2">Warden</option>
                        <option value="3">others</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="space-ar">
                  <input
                    className="inputcss"
                    id="inputid"
                    onChange={ownerdetailsinput}
                    type="text"
                    name="ownername"
                    placeholder="Caretaker Name"
                    required
                  />
                  <br />
                  <input
                    className="inputcss"
                    id="inputid"
                    onChange={ownerdetailsinput}
                    type="text"
                    name="ownerphone"
                    placeholder="Caretaker Phone No. "
                    required
                  />
                  <br />
                </div>
                <input
                  id="inputid3"
                  className="inputcss5"
                  name="addadditionalinfo"
                  onChange={otherpgdetailsinput}
                  type="text "
                  placeholder="Add additional info"
                  required
                />
              </div>

              {/* ///////////////////////////////////////////// Room details //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
              <h4 className="iconheading">Room Details</h4>

              {inputList.map((x, i) => {
                idris = idris * 10;
                return (
                  // <RoomdetailsForm i={i} idris = {idris} />
                  <div className="RoomInfo">
                    <div className="space-ar">
                      <div className="button-groups">
                        <p id="roomid" className="commonarea">
                          Room Type{i + 1}
                        </p>
                        {i > 0 ? (
                          <img
                            className="delet"
                            onClick={() => handleRemoveClick(i)}
                            src={"images/dustbin.png"}
                            alt="group"
                          />
                        ) : (
                          ""
                        )}
                      </div>

                      {/* <div className='inputgrp'>
               
                      <div class="checkbox-example">
                        <input type="radio" name='sharing' value={x.singlesharing}
              onChange={e => handleInputChange(e, i)} id={i+10}/>
                        <label for={i+10}>Single Sharing</label>
                        <p>
                         
                        </p>
                        </div> 
                      <div class="checkbox-example">
                        <input type="radio" name='sharing' value={x.doublesharing}
              onChange={e => handleInputChange(e, i)} id={i+20}/>
                        <label for={i+20}>Double sharing</label>
                        
                        </div> 
                      <div class="checkbox-example">
                        <input type="radio" name='sharing' value={x.triplesharing}
              onChange={e => handleInputChange(e, i)} id={i+30}/>
                        <label for={i+30}>Triple sharing</label>
                
                        </div> 
                      <div class="checkbox-example">
                        <input type="radio" name='sharing' value={x.threeplusharing}
              onChange={e => handleInputChange(e, i)} id={i+40}/>
                        <label for={i+40}>3+ sharing</label>
                      
                    
                    </div>  
                </div> */}

                      {/* <div className='inputgrp'>
               
               <div class="checkbox-example">
                 <input type="radio"  value={x.singlesharing}
       onChange={e => handleInputChange(e, i)} id={i+20}/>
                 <label for={i+20}>Single Sharing</label>
                 <p>
                  <p className='hiddentext'> 
                 {i=i+10}
                 </p>
                 </p>
                 </div> 
               <div class="checkbox-example">
                 <input type="radio"  value={x.doublesharing}
       onChange={e => handleInputChange(e, i)} id={i+21}/>
                 <label for={i+21}>Double sharing</label>
                 <p className='hiddentext'>
                 {i=i+10}
                 </p>
                 </div> 
               <div class="checkbox-example">
                 <input type="radio"  value={x.triplesharing}
       onChange={e => handleInputChange(e, i)} id={i+22}/>
                 <label for={i+22}>Triple sharing</label>
                  <p className='hiddentext'> 
                 {i=i+10}
                 </p>
                 </div> 
               <div class="checkbox-example">
                 <input type="radio"  value={x.threeplusharing}
       onChange={e => handleInputChange(e, i)} id={i+23}/>
                 <label for={i+23}>3+ sharing</label>
                  <p className='hiddentext'> 
                 {i=i+10}  
                 </p>
             
             </div>  
         </div>  */}

                      <div className="inputgrp">
                        <div class="checkbox-example2">
                          <input
                            type="checkbox"
                            name="singlesharing"
                            value={x.singlesharing}
                            onChange={(e) => handleInputChange2(e, i)}
                            checked={x.singlesharing}
                            id={idris + 10}
                          />
                          <label for={idris + 10}>Single Sharing</label>
                        </div>
                        <div class="checkbox-example2">
                          <input
                            type="checkbox"
                            name="doublesharing"
                            value={x.doublesharing}
                            onChange={(e) => handleInputChange2(e, i)}
                            checked={x.doublesharing}
                            id={idris + 20}
                          />
                          <label for={idris + 20}>Double sharing</label>
                        </div>
                        <div class="checkbox-example2">
                          <input
                            type="checkbox"
                            name="triplesharing"
                            value={x.triplesharing}
                            onChange={(e) => handleInputChange2(e, i)}
                            checked={x.triplesharing}
                            id={idris + 30}
                          />
                          <label for={idris + 30}>Triple sharing</label>
                        </div>
                        <div class="checkbox-example2">
                          <input
                            type="checkbox"
                            name="threeplusharing"
                            value={x.threeplusharing}
                            onChange={(e) => handleInputChange2(e, i)}
                            checked={x.threeplusharing}
                            id={idris + 40}
                          />
                          <label for={idris + 40}>3+ sharing</label>
                        </div>
                      </div>
                    </div>

                    <div className="space-ar">
                      <input
                        onChange={(e) => handleInputChange(e, i)}
                        id="inputid"
                        value={x.rent}
                        className="inputcss "
                        type="text"
                        name="rent"
                        placeholder="Rent"
                      />
                      <input
                        onChange={(e) => handleInputChange(e, i)}
                        id="inputid"
                        className="inputcss"
                        value={x.securitydeposit}
                        type="text"
                        name="securitydeposit"
                        placeholder="Security Deposit"
                      />
                    </div>
                    <input
                      className="inputcss5"
                      id="inputid3"
                      onChange={(e) => handleInputChange(e, i)}
                      type="text"
                      name="securitydeposit2"
                      value={x.securitydeposit2}
                      placeholder="Total no. of rooms of this type"
                    />
                    <br />

                    <h4 id="facilityid">Facilities Offered</h4>
                    <div className="space-ar inputgrp">
                      <div class="checkbox-example">
                        <input
                          type="checkbox"
                          onChange={(e) => handleInputChange2(e, i)}
                          checked={x.personalcupboard}
                          name="personalcupboard"
                          id={idris + 50}
                        />
                        <label for={idris + 50}>Personal cupboard</label>
                      </div>
                      <div class="checkbox-example">
                        <input
                          type="checkbox"
                          onChange={(e) => handleInputChange2(e, i)}
                          checked={x.tvinroom}
                          name="tvinroom"
                          id={idris + 60}
                        />
                        <label for={idris + 60}>TV in room</label>
                      </div>
                      <div class="checkbox-example">
                        <input
                          type="checkbox"
                          onChange={(e) => handleInputChange2(e, i)}
                          checked={x.tablechair}
                          name="tablechair"
                          id={idris + 70}
                        />
                        <label for={idris + 70}>Table chair</label>
                      </div>
                    </div>
                    <div className="space-ar inputgrp">
                      <div class="checkbox-example">
                        <input
                          type="checkbox"
                          onChange={(e) => handleInputChange2(e, i)}
                          checked={x.attachedbalocony}
                          name="attachedbalocony"
                          id={idris + 80}
                        />
                        <label for={idris + 80}>Attached balocony</label>
                      </div>
                      <div class="checkbox-example">
                        <input
                          type="checkbox"
                          onChange={(e) => handleInputChange2(e, i)}
                          checked={x.attachedbathroom}
                          name="attachedbathroom"
                          id={idris + 90}
                        />
                        <label for={idris + 90}>Attached bathroom</label>
                      </div>
                      <div class="checkbox-example">
                        <input
                          type="checkbox"
                          onChange={(e) => handleInputChange2(e, i)}
                          checked={x.ac}
                          name="ac"
                          id={idris + 100}
                        />
                        <label for={idris + 100}>AC</label>
                      </div>
                    </div>
                    <div></div>
                  </div>
                );
              })}
              <div className="continuebtn">
                <button className="addnew" onClick={handleAddClick}>
                  + Add new room type
                </button>
              </div>

              {/* ///////////////////////////////////////////// Security Amenities //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
              <h4 className="iconheading">Security Amenities</h4>

              <div className="icons">
                <div className=" Amenities">
                  <div className="space-ar inputgrp">
                    <div class="checkbox-example">
                      <input
                        type="checkbox"
                        onChange={securityinput}
                        value="cctv"
                        id="checkboxOneInput9"
                      />
                      <label className="label" for="checkboxOneInput9">
                        <GiCctvCamera size="50rem" />
                      </label>
                      <p id="icontxt">CCTV</p>
                    </div>

                    <div class="checkbox-example">
                      <input
                        type="checkbox"
                        onChange={securityinput}
                        value="security"
                        id="checkboxOneInput10"
                      />
                      <label className="label" for="checkboxOneInput10">
                        <SiAdguard size="50rem" />
                      </label>
                      <p id="icontxt">Security</p>
                    </div>
                    <div class="checkbox-example">
                      <input
                        type="checkbox"
                        onChange={securityinput}
                        value="biometric"
                        id="checkboxOneInput11"
                      />
                      <label className="label" for="checkboxOneInput11">
                        <FaFingerprint size="50rem" />
                      </label>
                      <p id="icontxt">Biometric</p>
                    </div>
                  </div>
                </div>

                {/* ///////////////////////////////////////////// Furnishing properties //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}

                <h4 className="iconheading">Furnishing in property</h4>
                <div className="Amenities">
                  <div className="space-ar inputgrp">
                    <div class="checkbox-example">
                      <input
                        type="checkbox"
                        onChange={furnishinginput}
                        value="fridge"
                        id="checkboxOneInput12"
                      />
                      <label className="label" for="checkboxOneInput12">
                        <RiFridgeLine size="50rem" />
                      </label>
                      <p id="icontxt">Fridge</p>
                    </div>

                    <div class="checkbox-example">
                      <input
                        type="checkbox"
                        onChange={furnishinginput}
                        value="washingmachine"
                        id="checkboxOneInput13"
                      />
                      <label className="label" for="checkboxOneInput13">
                        <GiWashingMachine size="50rem" />
                      </label>
                      <p id="icontxt">Washing machine</p>
                    </div>
                    <div class="checkbox-example">
                      <input
                        type="checkbox"
                        onChange={furnishinginput}
                        value="microwave"
                        id="checkboxOneInput14"
                      />
                      <label className="label" for="checkboxOneInput14">
                        <MdMicrowave size="50rem" />
                      </label>
                      <p id="icontxt">Microwave</p>
                    </div>
                    <div class="checkbox-example">
                      <input
                        type="checkbox"
                        onChange={furnishinginput}
                        value="waterpurifier"
                        id="checkboxOneInput15"
                      />
                      <label className="label" for="checkboxOneInput15">
                        <FaHandHoldingWater size="50rem" />
                      </label>
                      <p id="icontxt">Water Purifier</p>
                    </div>
                  </div>
                  <div className="space-ar inputgrp">
                    <div class="checkbox-example">
                      <input
                        type="checkbox"
                        onChange={furnishinginput}
                        value="gasstove"
                        id="checkboxOneInput16"
                      />
                      <label className="label" for="checkboxOneInput16">
                        <GiGasStove size="50rem" />
                      </label>
                      <p id="icontxt">Gas Stove</p>
                    </div>

                    <div class="checkbox-example">
                      <input
                        type="checkbox"
                        onChange={furnishinginput}
                        value="coffeemachine"
                        id="checkboxOneInput17"
                      />
                      <label className="label" for="checkboxOneInput17">
                        <MdCoffeeMaker size="50rem" />
                      </label>
                      <p id="icontxt">Coffee machine</p>
                    </div>
                    <div class="checkbox-example">
                      <input
                        type="checkbox"
                        onChange={furnishinginput}
                        value="tv"
                        id="checkboxOneInput18"
                      />
                      <label className="label" for="checkboxOneInput18">
                        <FiMonitor size="50rem" />
                      </label>
                      <p id="icontxt">TV</p>
                    </div>
                    <div class="checkbox-example">
                      <input
                        type="checkbox"
                        onChange={furnishinginput}
                        value="snackmachine"
                        id="checkboxOneInput19"
                      />
                      <label className="label" for="checkboxOneInput19">
                        <GiVendingMachine size="50rem" />
                      </label>
                      <p id="icontxt">Snack machine</p>
                    </div>
                  </div>
                </div>

                {/* ///////////////////////////////////////////// Services //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}

                <h4 className="iconheading">Services</h4>
                <div className="Amenities">
                  <div className="space-ar inputgrp">
                    <div class="checkbox-example">
                      <input
                        type="checkbox"
                        onChange={servicesinput}
                        value="laundry"
                        id="checkboxOneInput20"
                      />
                      <label className="label" for="checkboxOneInput20">
                        <RiShirtFill size="50rem" />
                      </label>
                      <p id="icontxt">Laundry</p>
                    </div>

                    <div class="checkbox-example">
                      <input
                        type="checkbox"
                        onChange={servicesinput}
                        value="housekeeping"
                        id="checkboxOneInput21"
                      />
                      <label className="label" for="checkboxOneInput21">
                        <GiBroom size="50rem" />
                      </label>
                      <p id="icontxt">House keeping</p>
                    </div>
                    <div class="checkbox-example">
                      <input
                        type="checkbox"
                        onChange={servicesinput}
                        value="wificonnectivity"
                        id="checkboxOneInput22"
                      />
                      <label className="label" for="checkboxOneInput22">
                        <AiOutlineWifi size="50rem" />
                      </label>
                      <p id="icontxt">wifi connectivity</p>
                    </div>
                  </div>
                </div>

                {/* ///////////////////////////////////////////// Top Amenities //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
                <h4 className="iconheading">Top Amenities</h4>
                <div className="Amenities">
                  <div className="space-ar inputgrp">
                    <div class="checkbox-example">
                      <input
                        type="checkbox"
                        onChange={topamenitiesinput}
                        value="library"
                        id="checkboxOneInput23"
                      />
                      <label className="label" for="checkboxOneInput23">
                        <IoLibrarySharp size="50rem" />
                      </label>
                      <p id="icontxt">Library</p>
                    </div>
                    <div class="checkbox-example">
                      <input
                        type="checkbox"
                        onChange={topamenitiesinput}
                        value="gym"
                        id="checkboxOneInput24"
                      />
                      <label className="label" for="checkboxOneInput24">
                        <CgGym size="50rem" />
                      </label>
                      <p id="icontxt">Gym</p>
                    </div>
                    <div class="checkbox-example">
                      <input
                        type="checkbox"
                        onChange={topamenitiesinput}
                        value="watersupply"
                        id="checkboxOneInput25"
                      />
                      <label className="label" for="checkboxOneInput25">
                        <GiWaterDrop size="50rem" />
                      </label>
                      <p id="icontxt">Water Supply</p>
                    </div>
                    <div class="checkbox-example">
                      <input
                        type="checkbox"
                        onChange={topamenitiesinput}
                        value="reservedparking"
                        id="checkboxOneInput26"
                      />
                      <label className="label" for="checkboxOneInput26">
                        <MdLocalParking size="50rem" />
                      </label>
                      <p id="icontxt">Reserved Parking</p>
                    </div>
                  </div>
                  <div className="space-ar inputgrp">
                    <div class="checkbox-example">
                      <input
                        type="checkbox"
                        onChange={topamenitiesinput}
                        value="lift"
                        id="checkboxOneInput27"
                      />
                      <label className="label" for="checkboxOneInput27">
                        <GiElevator size="50rem" />
                      </label>
                      <p id="icontxt">Lift</p>
                    </div>
                    <div class="checkbox-example">
                      <input
                        type="checkbox"
                        onChange={topamenitiesinput}
                        value="powerbackup"
                        id="checkboxOneInput28"
                      />
                      <label className="label" for="checkboxOneInput28">
                        <GiCarBattery size="50rem" />
                      </label>
                      <p id="icontxt">Power backup</p>
                    </div>
                    <div class="checkbox-example">
                      <input
                        type="checkbox"
                        onChange={topamenitiesinput}
                        value="swimmingpool"
                        id="checkboxOneInput29"
                      />
                      <label className="label" for="checkboxOneInput29">
                        <FaSwimmer size="50rem" />
                      </label>
                      <p id="icontxt">Swimming pool</p>
                    </div>
                    <div class="checkbox-example">
                      <input
                        type="checkbox"
                        onChange={topamenitiesinput}
                        value="tabletennis"
                        id="checkboxOneInput30"
                      />
                      <label className="label" for="checkboxOneInput30">
                        <FaTableTennis size="50rem" />
                      </label>
                      <p id="icontxt">Table Tennis</p>
                    </div>
                  </div>

                  <div className="space-ar inputgrp">
                    <div class="checkbox-example">
                      <input
                        type="checkbox"
                        onChange={topamenitiesinput}
                        value="dailynewspaper"
                        id="checkboxOneInput31"
                      />
                      <label className="label" for="checkboxOneInput31">
                        <ImNewspaper size="50rem" />
                      </label>
                      <p id="icontxt">Daily Newspaper</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* ///////////////////////////////////////////// Other Hostel detials //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}

              {/* ///////////////////////////////////////////// Address Details //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}

              <h4 className="iconheading">Address Details</h4>
              <div className="HostelInfo">
                <input
                  id="inputid"
                  className="inputcss9"
                  onChange={addressdetailsinput}
                  name="city"
                  type="text"
                  placeholder="City *"
                  required
                />
                <input
                  id="inputid"
                  className="inputcss9"
                  onChange={addressdetailsinput}
                  name="building"
                  type="text"
                  placeholder="Building/ Society *"
                  required
                />
                <input
                  id="inputid"
                  className="inputcss9"
                  onChange={addressdetailsinput}
                  name="locality"
                  type="text"
                  placeholder="Locality *"
                  required
                />
                <input
                  id="inputid"
                  className="inputcss9"
                  onChange={addressdetailsinput}
                  name="landmark"
                  type="text"
                  placeholder="Landmark *"
                  required
                />
                <input
                  id="inputid"
                  className="inputcss9"
                  onChange={addressdetailsinput}
                  name="state"
                  type="text"
                  placeholder="State *"
                  required
                />
              </div>
            </div>
            <div className="continuebtn">
              <button type="submit" onClick={submitted} className="continue">
                {/* <Link to="/Addimages">Continue</Link>*/} submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
