import React from "react";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../Context/UserContext";
import { Link } from "react-router-dom";
import { auth } from "../firebase.config";
import { AuthContext } from "../Context/AuthContext";

const LoginWithEmail = () => {
  const [emailid, setemailid] = useState("");
  const [password, setpassword] = useState("");
  const cont = useContext(UserContext);
  const auth = useContext(AuthContext);
  const history = useHistory();

  const auths = (e) => {
    console.log(e.target.value);
    e.target.name === "email"
      ? setemailid(e.target.value)
      : setpassword(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const result = await cont.login(emailid, password);
    console.log("result login = ", result.user.uid);
    if (result.user.uid !== null) {
      history.push("/Home");
    }
  };

  const onForget = async (e) => {
    e.preventDefault();
    await cont.forgotPassword(emailid);
  };

  return (
    <div className="LoginContainer">
      <div className="authbox1" style={{ padding: "2%" }}>
        <h1 className="loginheading" style={{ marginBottom: "1%" }}>
          Login{" "}
        </h1>
        <div className="alignment">
          <img
            src="https://image.freepik.com/free-vector/mobile-login-concept-illustration_114360-83.jpg"
            alt="images"
          />
          <div>
            <div className="cont">
              <div className="loginform logincss">
                <form className="formcss">
                  <br />
                  <br />
                  <input
                    type="text"
                    onChange={auths}
                    name="email"
                    placeholder="Email *"
                    required
                  />
                  <br />
                  <br />
                  <br />
                  <input
                    type="password"
                    onChange={auths}
                    placeholder="Password *"
                    required
                  />
                  <button
                    id="smalltxt"
                    onClick={onForget}
                    style={{ cursor: "pointer", color: "blue" }}
                  >
                    Forgot Password?
                  </button>
                  {/* <button className="servicebtn">
                    <Link to="/LoginWithMobile" className="textdec">
                      Login With Mobile
                    </Link>
                  </button> */}

                  <div className="centeralglogin">
                    <button
                      type="submit"
                      onClick={onSubmit}
                      className=" logbtn"
                    >
                      Login
                    </button>
                  </div>
                  <p id="smalltxt">
                    Don't have Registered yet?
                    <Link to="/Register"> Register</Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoginWithEmail;
