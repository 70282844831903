const initialvalue = null;

export const pgformreducer = (state = initialvalue, action) => {
  console.log("form in pgformreducer", action);
  switch (action.type) {
    case "pgformaction":
      return action.payload;
    case "pgformempty":
      return null;
    default:
      return state;
  }
};
