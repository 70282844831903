import React, { useContext, useEffect, useState } from "react";
import { db } from "../firebase.config";
import {
  getDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  query,
  doc,
  collection,
  addDoc,
  where,
} from "firebase/firestore";
import { setDoc } from "firebase/firestore";
import "bootstrap/dist/css/bootstrap.min.css";
import Menu from "./Menu";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useHistory,
  useParams,
} from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../css/Form1.css";
import "../css/showproperty.css";
import { GiCctvCamera } from "react-icons/gi";
import { SiAdguard } from "react-icons/si";
import { FaFingerprint } from "react-icons/fa";
import { RiFridgeLine } from "react-icons/ri";
import { GiWashingMachine } from "react-icons/gi";
import { MdMicrowave } from "react-icons/md";
import { FiMonitor } from "react-icons/fi";
import { MdCoffeeMaker } from "react-icons/md";
import { GiGasStove } from "react-icons/gi";
import { GiVendingMachine } from "react-icons/gi";
import { RiShirtFill } from "react-icons/ri";
import { GiBroom } from "react-icons/gi";
import { AiOutlineWifi } from "react-icons/ai";
import { CgGym } from "react-icons/cg";
import { GiWaterDrop } from "react-icons/gi";
import { MdLocalParking } from "react-icons/md";
import { GiElevator } from "react-icons/gi";
import { GiCarBattery } from "react-icons/gi";
import { FaSwimmer } from "react-icons/fa";
import { IoLibrarySharp } from "react-icons/io5";
import { FaHandHoldingWater } from "react-icons/fa";
import { FaTableTennis } from "react-icons/fa";
import { ImNewspaper } from "react-icons/im";

export default function ShowProperty() {
  const history = useHistory();
  const params = useParams();

  const [docsy, setdocsy] = useState(null);
  console.log("params.id", params.id);
  const [form, setform] = useState(null);

  const docRef = doc(db, "HostelObject", params.id);

  // setTimeout(() => {
  //     if(cont.user == null)
  //     {
  //         history.push('/login')
  //     }

  // }, 3000);

  useEffect(async () => {
    getDoc(docRef).then((doc) => {
      console.log("document = ", doc.data());
      setdocsy(doc.data());
    });
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div>
      {docsy !== null ? (
        <>
          {/* <Carousel style={{ width: "100%" }}>
            <Carousel.Item className="carouselclass">
              <img
                className="d-block w-auto"
                src={docsy.images[0]}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item className="carouselclass">
              <img
                className="d-block w-auto"
                src={docsy.images[1]}
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item className="carouselclass">
              <img
                className="d-block w-auto"
                src={docsy.images[2]}
                alt="Third slide"
              />
            </Carousel.Item>
          </Carousel> */}
          <Carousel responsive={responsive}>
            {docsy.images.map((image) => (
              <div>
                <Carousel.Item className="carouselclass">
                  <img
                    className="d-block w-auto"
                    src={image}
                    alt="Third slide"
                  />
                </Carousel.Item>
              </div>
            ))}
          </Carousel>
          ;
          <div className="contentlayout ">
            <h4 className="sph4"> {docsy.hosteldetails.inputs.hostelname}</h4>
            <p className="sppara">
              <i class="fa fa-globe"></i>By Samnani Group
              <br />
              Dwarka-Nashik, Nashik
            </p>
            <h4 className="sph4">Amenities</h4>
            <div className="contentboxx">
              <div className="space1">
                <div className="spacediv">
                  {docsy.topamenities.gym === true ? (
                    <img src={<CgGym size="3rem" />} alt="images" />
                  ) : (
                    "GYM = false"
                  )}
                </div>
                <div className="spacediv">
                  {docsy.topamenities.lift === true ? (
                    <img src={<GiElevator size="3rem" />} alt="images" />
                  ) : (
                    "lift= false"
                  )}
                </div>
                <div className="spacediv">
                  {docsy.topamenities.powerbackup === true ? (
                    <img src={<GiCarBattery size="3rem" />} alt="images" />
                  ) : (
                    "powerbackup= false"
                  )}
                </div>
                <div className="spacediv">
                  {docsy.topamenities.reservedparking === true ? (
                    <label className="label" for="checkboxOneInput26">
                      <img src={<MdLocalParking size="3rem" />} alt="images" />
                    </label>
                  ) : (
                    "reservedparking = false"
                  )}
                </div>
                <div className="spacediv">
                  {docsy.topamenities.watersupply === true ? (
                    <img src={<GiWaterDrop size="3rem" />} alt="images" />
                  ) : (
                    "watersupply = false"
                  )}
                </div>
              </div>
            </div>

            <h4 className="sph4">
              Specifications of {docsy.hosteldetails.inputs.hostelname}
            </h4>
            <div className="contentboxx1">
              <div className="space">
                <div>
                  <p className="specifications">PG for girls</p>
                </div>
                <div>
                  <p className="specifications">Available meals</p>
                </div>
                <div>
                  <p className="specifications">Triple sharing rooms</p>
                </div>
                <div>
                  <p className="specifications">under construction</p>
                </div>
              </div>
            </div>

            <h4 className="sph4">About Ishwar Pratik Grand</h4>
            <div className="contentboxx2">
              <p className="sppara">
                "Amidst the confluence of serenity and calmness, next to the
                dream city at Nashik road near Inox cinemas is your idyllic
                abode. These select spacious and plush 2, 3 & 4 bedroom
                apartments are designed to ensure you have the luxury of space
                and utmost comfort. Two real estate giants Samnani Group{" "}
              </p>
            </div>

            <h4 className="sph4">
              Rent Details of {docsy.hosteldetails.inputs.hostelname}
            </h4>
            <div className="contentboxx1">
              <div className="space">
                <div>
                  <h5 className="sph5">Rent</h5>
                  <p className="sppara2">{docsy.roomdetails[0].rent}</p>
                </div>
                <div>
                  <h5 className="sph5">Security Deposit</h5>
                  <p className="sppara2">
                    {docsy.roomdetails[0].securitydeposit}
                  </p>
                </div>
                <div>
                  <h5 className="sph5">Meal Charge per month</h5>
                  <p className="sppara2">
                    {docsy.otherpgdetails.mealchargepermonth}
                  </p>
                </div>
                <div>
                  <h5 className="sph5">status</h5>
                  <p className="sppara2">{docsy.roomdetails[0].rent}</p>
                </div>
              </div>
            </div>

            {/* <div className='space-around'>
                      <div>
                        <h5>status</h5>
                        <p>under construction</p>
                      </div> 
                      <div>
                        <h5>status</h5>
                        <p>under construction</p>
                      </div> 
                      <div>
                        <h5>status</h5>
                        <p>under construction</p>
                      </div> 
                      <div>
                        <h5>status</h5>
                        <p>under construction</p>
                      </div> 
                    </div> */}
          </div>
        </>
      ) : (
        "not found"
      )}
    </div>
  );
}
