import react from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Card,
  Button,
  InputGroup,
  FormControl,
  Form,
  Table,
} from "react-bootstrap";

const PostProperty = () => {
  return (
    <div className="carousel">
      <img
        className="carouselImg"
        src="https://media.istockphoto.com/vectors/smart-city-landscape-city-center-with-many-building-airplane-is-in-vector-id1152829376"
        alt="images"
      />
      <div className="carouselText">
        <h1>LITRENTAL</h1>
        <h5>LIVE IN TEMPORARY RENTAL</h5>
        <p>
          Struggling to find a place to crash in? Brokers are making you broke?
          Start your BROKERAGE FREE journey with LitRental.
        </p>
        <button className="servicebtn ">
          <a
            href="#h1subservice"
            style={{ textDecoration: "none", color: "#fff" }}
          >
            {" "}
            Start
          </a>
        </button>
      </div>
    </div>
  );
};

export default PostProperty;
