export const formaction = (form) => {
  console.log("action = ", form);
  return {
    type: "formaction",
    payload: form,
  };
};

export const rentaction = (form) => {
  console.log("action = ", form);
  return {
    type: "rentaction",
    payload: form,
  };
};

export const pgformaction = (form) => {
  console.log("action = ", form);
  return {
    type: "pgformaction",
    payload: form,
  };
};

export const studioformaction = (form) => {
  console.log("action = ", form);
  return {
    type: "studioformaction",
    payload: form,
  };
};
